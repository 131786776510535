// Some older AngularJS libraries use now-deprecated jQuery functions, so must include `jquery-migrate` and also muting console.log() messages
window.jQuery = window.$ = require('jquery/dist/jquery.js')
require('jquery-migrate/dist/jquery-migrate.js')
window.jQuery.migrateMute = true
require('angular/angular.js')
require('angular-animate/angular-animate.js')
require('angular-aria/angular-aria.js')
require('angular-cookies/angular-cookies.js')
require('angular-route/angular-route.js')
require('angular-resource/angular-resource.js')
require('angular-sanitize/angular-sanitize.js')

window.d3 = require('d3/d3.js')
window.nv = require('nvd3/build/nv.d3.js')
require('angular-bootstrap/dist/angular-bootstrap-tpls.js')
require('angular-file-upload/dist/angular-file-upload.js')
require('angular-http-auth/src/http-auth-interceptor.js')
require('angular-payments/lib/angular-payments.js')
require('angular-rt-popup/dist/angular-rt-popup.js')
require('angular-translate/dist/angular-translate')
require('angular-translate/dist/angular-translate-loader-partial/angular-translate-loader-partial')
require('angular-money-directive/dist/angular-money-directive.min.js')
require('angular-scroll/angular-scroll.js')
require('angular-metatags/angular-metatags-module/angular-metatags.js')
require('angular-ui-mask/dist/mask.js')
require('angular-ui-scrollfix/scrollfix.js')
require('angular-ui-select/dist/select.js')
require('ngtoast/dist/ngToast.js')
require('marked/lib/marked.js')
require('ngclipboard/dist/ngclipboard.js')
require('waypoints/lib/noframework.waypoints.js')
require('angular-marked/angular-marked.js')
require('angular-raven/angular-raven.js')
require('angular-nvd3/dist/angular-nvd3.js')
require('sticky-table-headers/js/jquery.stickytableheaders.js')
require('angucomplete-alt/angucomplete-alt.js')
window.Clipboard = require('clipboard/dist/clipboard.js')
window.PDFObject = require('pdfobject/pdfobject.js')
window.Mailcheck = require('mailcheck/src/mailcheck.js')
window.SVG = require('svg.js/dist/svg.js')
window.SvgPanZoom = require('svg-pan-zoom/src/browserify.js')
window.iFrameResize = require('iframe-resizer/js/iframeResizer.js')
window.Cassowary = require('cassowary-js/bin/c.js')
